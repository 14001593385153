export default {
  breakpoints: {
    thresholds: {
      xl: 9999,
    },
  },
  theme: {
    disable: true,
    options: { customProperties: true },
    themes: {
      light: {
        cyan123: '#5FBEC3',
        pink123: '#F03899',
        orange123: '#F9653B',
        anchor: '#555555',
      },
    },
  },
};
